import React from 'react'
import { ProductionListProps } from './types'
import Image from '@/components/base/image'
import { Column, Row } from '@/components/base/gridview'

const ProductionList: React.FunctionComponent<ProductionListProps> = ({
  data = [],
}) => {
  return (
    <Row className="production-list">
      {data.map((item, index) => (
        <Column
          xs={{ size: 12 }}
          sm={{ size: 12 }}
          md={{ size: 12 }}
          lg={{ size: 4 }}
          xl={{ size: 4 }}
          key={index}
        >
          <div className="item">
            <figure className="item-thumbnail">
              <Image {...item.thumbnail} alt={item.title} />
            </figure>
            <div className="item-detail">
              <div className="title">{item.title}</div>
              <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: item.desc }}
              />
            </div>
          </div>
        </Column>
      ))}
    </Row>
  )
}

export default ProductionList
